import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Transcript`}</h2>
    <p><em parentName="p">{`The tinwheel recorder is pressed, and we hear the sound of instruments whirring in the background.`}</em></p>
    <p>{`ENZO: This is Dr. Enzo Quint, recording my latest experiment regarding the strange phenomenon around thread severance, and it's connection to the place known as the Dark Between. Ari, the Bifurcator?`}</p>
    <p>{`ARI: Enzo... are you sure we should be doing this? We have no idea what is going to happen.`}</p>
    <p>{`ENZO: We understand the risks, Ari. As does Mr. Tulls. Jurrin, please read from the page before you.`}</p>
    <p><em parentName="p">{`Jurrin's voice comes from behind a pane of glass, slightly muffled and shakey.`}</em></p>
    <p>{`JURRIN: M-My name is Jurrin Tulls. I am an engineer at the Bathysward, and I am participating in this endeavor of my own free will, understanding that I will have my thread severed. I willingly accept all risks.`}</p>
    <p>{`ENZO: Thank you, Jurrin. Empyreal, you may begin.`}</p>
    <p><em parentName="p">{`The sound of heavy footsteps can be heard along with clanging metal as the Bifurfactor is placed on Jurrin's chest. Jurrin breathes heavily.`}</em></p>
    <p>{`ENZO: I am activating the device, now.`}</p>
    <p><em parentName="p">{`Steam hisses and Jurrin yelps. Sensors whirr and electricity sparks. Jurrin begins to scream. A burst of static overwhelms the recording device, then all goes quite.`}</em></p>
    <p>{`ARI: What... what happened?`}</p>
    <p>{`ENZO: Mr. Tulls? Can you hear us?`}</p>
    <p><em parentName="p">{`Jurrin's voice is now distorted an echoey.`}</em></p>
    <p>{`JURRIN: I see.... yes..... yes.... I seeeee.`}</p>
    <p>{`ARI: Luminary above, what's wrong with his eyes? Enzo, what have we done?!`}</p>
    <p><em parentName="p">{`Jurrin continues to babble in the background.`}</em></p>
    <p>{`JURRIN: The end and the beginning, and... nothing... yes I see it, not meant for our eyes, not meant for this woooorllldd...`}</p>
    <p><em parentName="p">{`Glass begins to crack. Sensors begin to whirr wildly, and alarms start blaring.`}</em></p>
    <p>{`ENZO: Jurrin? Jurrin? `}</p>
    <p><em parentName="p">{`The glass encasing Jurrin shatters, and the lab errupts into chaos.`}</em></p>
    <p>{`ARI: Enzo, we have to run!`}</p>
    <p>{`ENZO: The tinwheel, Ari! The---`}</p>
    <p><em parentName="p">{`The tinwheel cuts off abruptly.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      